<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>Submeter exames de agendamentos</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="items"
          item-key="id"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:top>
            <v-expansion-panels class="mb-5" :value="0">
              <v-expansion-panel>
                <v-expansion-panel-header> Filtros </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-container fluid>
                    <v-row
                      ><v-col>
                        <v-text-field
                          label="Paciente"
                          placeholder="Pesquise pelo nome/cpf/rg do paciente"
                          v-model="filters.searchPatient"
                        >
                        </v-text-field
                      ></v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-text-field
                          label="Data inicial"
                          v-model="filters.startDate"
                          type="date"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Data final"
                          v-model="filters.endDate"
                          type="date"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <div class="d-flex justify-end align-center flex-wrap">
                      <v-btn color="success" class="mr-2" @click="loadExams()">
                        <v-icon left>mdi-filter</v-icon> Filtrar</v-btn
                      >
                      <v-btn @click="clearFilters"
                        ><v-icon left>mdi-eraser</v-icon> Limpar</v-btn
                      >
                    </div>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              icon
              title="Submeter exame"
              @click="openInsertExamScreen(item.id)"
            >
              <v-icon> mdi-cloud-upload </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div class="text-center mt-2">
      <v-pagination
        v-model="currentPage"
        :length="numberOfPages"
        @input="loadExams"
      ></v-pagination>
    </div>

    <v-dialog max-width="300" persistent v-model="downloadExamsDialog">
      <v-card>
        <v-card-title>Baixando arquivos...</v-card-title>
        <v-card-text>
          <div class="pa-5 d-flex justify-center align-center">
            <v-progress-circular indeterminate></v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";

export default {
  components: {},
  data() {
    return {
      currentPage: 1,
      resultsPerPage: 50,
      totalRows: 0,
      search: null,
      loading: false,
      loadingExamModes: false,
      loadingPartnerships: false,
      downloadExamsDialog: false,
      headers: [
        { text: "Data do exame", value: "dateOfRealization" },
        { text: "Exame", value: "examName" },
        { text: "Paciente", value: "patientName" },
        { text: "Filial/Matriz", value: "Scheduling.CompanyBranch.companyName" },
        { text: "Ações", value: "actions", sortable: false },
      ],
      items: [],
      filters: {
        searchPatient: null,
        startDate: null,
        endDate: null,
      },
      timer: setInterval(() => {
        this.loadExams();
      }, 60000),
    };
  },
  methods: {
    async loadExams(page) {
      try {
        this.loading = true;

        let currentPage = page ? page : 1;

        const params = {
          pagination: 1,
          page: currentPage,
          ...this.filters,
        };

        let url = `/medical-reports/procedures`;

        const response = await this.$axios.get(url, { params });

        this.totalRows = response.data.count ? response.data.count : 0;

        this.setResponseData(response.data.rows);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setResponseData(data) {
      const copyOfData = [...data];

      this.items = copyOfData.map((item) => {
        item.patientName = item.Scheduling.Patient.Person.fullName;

        item.dateOfRealization = this.$moment(
          item.Scheduling.dateOfTheConclusion
        )
          .add("3", "hours")
          .format("DD/MM/YYYY [ás] HH:mm");

        item.examName = item.Procedure.name;
        
        return item;
      });
    },
    openInsertExamScreen(schedulingProcedureId) {
      this.$router.push(
        `/agendamentos/${schedulingProcedureId}/exames/cadastrar`
      );
    },
    async handleErrors(error) {
      console.log(error);
      const errorHandled = errorHandler.treatError(error);

      await this.$root.$errorDialog(errorHandled, {
        width: "800",
        color: "primary",
      });
    },
    clearFilters() {
      this.filters = {
        searchPatient: null,
        startDate: null,
        endDate: null,
      };
      this.currentPage = 1;
      this.loadExams();
    },
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.totalRows / this.resultsPerPage);
    },
  },
  created() {
    this.loadExams();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style></style>
